import api from './api'



class  FrontendService {

    async getblogdata(search, categoryid, companyid, pagesize) {
        return await api.get(`Blog/GetBlogList/${search}/${categoryid}/${companyid}/${pagesize}`)
    }
    async getblogcategorylist(userid) {
        return await api.get(`Blog/blogcategorylist/${userid}`)
    }
    async getblogdetailbyid(id) {
        return api.get(`Blog/GetBlogbyid/${id}`)
    }
    async GetPackageList(searchModel) {
        return await api.post(`GetPackageList`, searchModel);
    }
    async GetCountryList(masterNames, masterKeyText, userId) {
        return await api.get(`GetMasterItems/${masterNames}/${masterKeyText}/${userId}`);
    }
    async GetStateList() {
        return await api.get('GetStateList');
    }
    async GetPackageDetailsById(subscriptionId) {
        return await api.get(`GetPackageDetialsByID/${subscriptionId}`);
    }
    async AddEditCompany(companyData) {
        return await api.post(`ManageCompany/AddEditCompany`, companyData);
    }
    async savecontactus(contactusform) {
        return api.post(`ContactUs`, contactusform)
    }
    async GetDynamicPageContent(pageURL, companyId = null) {
        companyId = (companyId == null) ? '00000000-0000-0000-0000-000000000000' : companyId;
        return await api.get(`DynamicPages/GetDynamicPageContent/${pageURL}/${companyId}`);
        //return await api.get(`DynamicPages/GetDynamicPageContent/${pageURL}/${companyId}`);
    }
    async AddSubscriber(manageSubscribersModel) {
        return await api.post(`ManageSubscribers/AddEditSubscribers`, manageSubscribersModel);
    }
    async UnSubscribed(Email, companyId = null) {
        companyId = (companyId == null) ? '00000000-0000-0000-0000-000000000000' : companyId;
        return await api.get(`ManageSubscribers/UnsubscribeNewletter/${Email}/${companyId}`)
    }
    async getTestimonailList() {
        let companyId = '00000000-0000-0000-0000-000000000000';
        return await api.get(`ManageTestimonial/getTestimonailList/${companyId}`);
    }
    async getnewslist(id, companyid, pagesize) {
        if (companyid == null || companyid=='')
            companyid = 'null'
        if (id == null || id)
            id = 'null'
        return api.get(`ContactUs/getnewslist/${id}/${companyid}/${pagesize}`)
    }
    async getnewsdetailbyid(id) {
        debugger
        return api.get(`ContactUs/getnewsbyid/${id}`)
    }
    async GetSocialLinkList(searchFields, sortColumn, sortDir, pageIndex, pageSize, userId, companyId, status) {
        if (typeof searchFields == "undefined" || searchFields == null || searchFields == "") {
            searchFields = "00Test";

            return await api.get(`SocialMediaLink/${searchFields}/${sortColumn}/${sortDir}/${pageIndex}/${pageSize}/${userId}/${companyId}/${status}`);
        }
    }
    async GetTopFiveCategory(isMarketPlace, companyId, IsFeateured) {
        //debugger;
        return await api.get(`HomeFront/GetTopFiveCategory/${isMarketPlace}/${IsFeateured}/${companyId}`);
    }
    async GetHeaderFooterData(userId, companyId) {
        return await api.get(`DynamicCompanyMenu/GetCompanyMenuByHeaderFooter/${userId}/${companyId}`)
    }
    async GetCompanyDetailsByDomainOrName(companyDomainName, companyUniqueName) {
        return await api.get(`ManageCompanyWebPage/GetCompanyDetailsByDomainOrName/${companyDomainName}/${companyUniqueName}`);
    }
    async GetdynamicMetadata(model) {
        return await api.post(`DynamicCompanyMenu/GetDynamicMetadata`, model);
    }
    //async countryList(masterNames, masterKeyText, userId) {
    //    return await api.get(`userList/GetMasterItems/${masterNames}/${masterKeyText}/${userId}`);
    //}
    //async getStateList() {
    //    return await api.get('UserList/GetStateList');
    //}
    async AddUpdateAddress(model) {
        debugger;
        //return await api.post(`Customer/UpdateAddressDetails`, model)
        if (model.AddressId != null) {
            return await api.post(`Customer/EditAddressDetails`, model)
        }
        else {
            return await api.post(`Customer/UpdateAddressDetails`, model)
        }
    }
    async GetuserShippingbillingdata(CustomerLoginUserId, CurrentCartId, AddressTyype, companyId) {
        return await api.get(`FrontendShop/CheckOut/${CustomerLoginUserId}/${CurrentCartId}/${AddressTyype}/${companyId}`)
    }
    async GetBillingShippingAddressListing(CustomerLoginUserId, CurrentCartId, AddressTyype, addressId) {
        debugger;
        return await api.get(`FrontendShop/CheckOutnewbilling/${CustomerLoginUserId}/${CurrentCartId}/${AddressTyype}/${addressId}`)
    }
    async DeleteBillingShippingAddressListingRecord(Model) {
        debugger;
        return await api.post(`Customer/DeleteBillingAddressRecord`, Model);
    }
    async SetDefaultAddress(model) {
        return await api.post(`Customer/updateSetdefultvalue`, model)
    }
    async EditUpdateAddress(model) {
        debugger;
        if (model.AddressId != null) {
            return await api.post(`Customer/EditAddressDetails`, model)
        }
        else {
            return await api.post(`Customer/UpdateAddressDetails`, model)
        }

    }
    async GetNotificationList(searchModel) {
        debugger;
        return api.post('ManageNotification/GetNotificationList', searchModel);
    }
    async ChangeNotificationStatusById(userId, status) {
        return await api.get(`ManageNotification/ChangeNotificationStatusById/${userId}/${status}`)
    }
    async DeleteNotificationById(id, UserId, CompanyId) {
        debugger;
        return await api.get(`ManageNotification/DeleteNotificationById/${id}/${UserId}/${CompanyId}`)
    }
    async LogIn(model) {

        return await api.post('Customer/CustomerLogin', model);
    }
    async getCurrentUserDetail(userId, actionType) {
        let optionaParamersType = "00Test";
        await api.get(`UserProfile/GetUserInfo/${userId}/${actionType}/${optionaParamersType}`).then(response => (
            localStorage.setItem('userinfo', JSON.stringify(response.data))
        ))
    }
    forgotpassword(forgotmodel) {
        debugger;
        return api.post('Account/ForgotPassword', forgotmodel);
    }
    forgotpasswordfromst(forgotmodel) {
        debugger;
        return api.post('Account/ForgotPasswordFromSt', forgotmodel);
    }
    async resetPassword(setPassword) {
        return await api.post(`Account/ResetPassword`, setPassword);
    }
    async GetEasyshipList(data) {
        debugger;
        return await api.post(
            'Shipping/Createshipment',
            data,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
    }
    async GetShippingRateList(data) {
        return await api.post(
            'Shipping/GetShipmentsList',
            data,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
    }
    async ReturnShipment(shipmentId, orderId) {
        var companyId = localStorage.getItem("companyId");
        return await api.post(`Shipping/ReturnShipment/${shipmentId}/${orderId}/${companyId}`);
    }
    async CancelShipment(shipmentId, orderId) {
        var companyId = localStorage.getItem("companyId");
        return await api.post(`Shipping/CancelShipment/${shipmentId}/${orderId}/${companyId}`);
    }
    async CancelOrder(orderId, companyId, status) {
        return await api.post(`Orders/CancelOrder/${orderId}/${companyId}/${status}`);
    }
    async ProcessTopdawgOrders(orderId, topDawgOrderId, companyId) {
        var companyId = localStorage.getItem("companyId");
        return await api.post(`FrontendShop/ProcesstopdawgOrder/${orderId}/${topDawgOrderId}/${companyId}`);
    }
    async GetNewsList(searchFields, sortColumn, sortDir, pageIndex, pageSize, userId, companyId) {
        if (typeof searchFields == "undefined" || searchFields == null || searchFields == "") { searchFields = "00Test"; }
        return await api.get(`Blog/GetLatestPost/${searchFields}/${sortColumn}/${sortDir}/${pageIndex}/${pageSize}/${userId}/${companyId}`);

    }
}

export default new FrontendService();