

import moment from 'moment';

const PaginatorWithPaging = 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown';
const PaginatorWithoutPaging = 'RowsPerPageDropdown';

var rightNow, jan1, june1, temp, jan2, june2;
function calculate_time_zone() {
    var minutes;

    localStorage["TimeZone"] != null;
    rightNow = new Date();
    jan1 = new Date(rightNow.getFullYear(), 0, 1, 0, 0, 0, 0);  // jan 1st
    june1 = new Date(rightNow.getFullYear(), 6, 1, 0, 0, 0, 0); // june 1st
    temp = jan1.toUTCString();
    jan2 = new Date(temp.substring(0, temp.lastIndexOf(" ") - 1));
    temp = june1.toUTCString();
    var june2 = new Date(temp.substring(0, temp.lastIndexOf(" ") - 1));
    var std_time_offset = (jan1 - jan2) / (1000 * 60 * 60);
    var daylight_time_offset = (june1 - june2) / (1000 * 60 * 60);
    var dst;
    if (std_time_offset == daylight_time_offset) {
        dst = "0"; // daylight savings time is NOT observed
    } else {
        // positive is southern, negative is northern hemisphere
        var hemisphere = daylight_time_offset - std_time_offset;
        if (hemisphere >= 0) {
            std_time_offset = daylight_time_offset;
            dst = "1"; // daylight savings time is observed
        }
    }
    var i;
    // Here set the value of hidden field to the ClientTimeZone.
    return minutes = localStorage["LocalTime"] = convert(std_time_offset);

}
var dtMonth, sDay, sYear, sHour, sMinute, sAMPM;
function formatDate(dateVal) {
    var newDate = new Date(dateVal);

    //var sMonth = padValue(newDate.getMonth() + 1);
    dtMonth = padValue(newDate.getMonth() + 1);
    sDay = padValue(newDate.getDate());
    sYear = newDate.getFullYear();
    sHour = newDate.getHours();
    sMinute = padValue(newDate.getMinutes());

    if (isNaN(dtMonth))
        return;
    sAMPM = "AM";

    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
        sAMPM = "PM";
        sHour = iHourCheck - 12;
    }
    else if (iHourCheck === 0) {
        sHour = "12";
    }

    sHour = padValue(sHour);

    switch (dtMonth) {
        case '01':
            dtMonth = '01';
            break;
        case '02':
            dtMonth = '02';
            break;
        case '03':
            dtMonth = '03';
            break;
        case '04':
            dtMonth = '04';
            break;
        case '05':
            dtMonth = '05';
            break;
        case '06':
            dtMonth = '06';
            break;
        case '07':
            dtMonth = '07';
            break;
        case '08':
            dtMonth = '08';
            break;
        case '09':
            dtMonth = '09';
            break;
        case '10':
            dtMonth = '10';
            break;
        case '11':
            dtMonth = '11';
            break;
        case '12':
            dtMonth = '12';
            break;
    }
    return dtMonth + "-" + sDay + "-" + sYear + " " + sHour + ":" + sMinute + " " + sAMPM;
}

function padValue(value) {
    return (value < 10) ? "0" + value : value;
}
// This function is to convert the timezoneoffset to Standard format
function convert(value) {

    var hours = parseInt(value);
    value -= parseInt(value);
    value *= 60;
    var mins = value;
    value -= parseInt(value);
    value *= 60;
    var secs = parseInt(value);
    var display_hours = hours.toString();
    // handle GMT case (00:00)
    if (hours == 0) {
        display_hours = "00";
    } else if (hours > 0) {
        // add a plus sign and perhaps an extra 0
        display_hours = ((hours < 10) ? "+0" + hours : "+" + hours).toString();
    } else {
        // add an extra 0 if needed
        display_hours = ((hours > -10) ? "-0" + Math.abs(hours) : hours).toString();
    }
    mins = (mins < 10) ? "0" + mins : mins;
    return display_hours + ":" + mins;
}
function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}
var timeOffSet, hourOffSet, minuteOffSet,
    minutesOffSet, preValOffSet, hoursOffSet, final;
function convertOffset(gmt_offset) {
    timeOffSet = gmt_offset.toString().split(":");
    hourOffSet = parseInt(timeOffSet[0]);
    minuteOffSet = parseInt(timeOffSet[1]);
    minutesOffSet = minuteOffSet;
    preValOffSet = hourOffSet < 0 ? "-" : "+";
    hoursOffSet = Math.abs(hourOffSet) < 10 ? "0" + Math.abs(hourOffSet) : Math.abs(hourOffSet);
    //hour = negative ? "-" + hour : "+" + hour;
    final = parseInt(hoursOffSet);
    let data = final * 60;

    return preValOffSet + parseInt(data + minutesOffSet);
}
function GetLocalTimeFromUTC(date, formatRequired) {

    var dateStringWithTime = moment(date).format('DD-MM-YYYY HH:mm:ss');
    var dateArr = dateStringWithTime.split('-');
    var val = dateArr[1] + '/' + dateArr[0] + '/' + dateArr[2];
    var dt_ = new Date(val);
    if (localStorage["LocalTime"] == null) {
        calculate_time_zone();
    }

    var TimeZoneHours = convertOffset(localStorage["LocalTime"]);
    let MILLISECS_PER_HOUR = TimeZoneHours /* min/hour */ * 60 /* sec/min */ * 1000 /* ms/s */;
    var newDate_ = new Date(+dt_ + MILLISECS_PER_HOUR);
    if (IsNull(formatRequired) != null) {
        newDate_ = moment(newDate_).format(formatRequired);
        return newDate_
    }
    return formatDate(newDate_);
}



function IsNull(value, CheckEmptyString = true, replaceValue = null) {
    if (value != null && value != undefined && value != 'null' && value != 'undefined') {
        if (CheckEmptyString == true) {
            return value != '' ? value : replaceValue;
        }
        return value;
    }
    else {
        return replaceValue;
    }
}


function dateFormat(date, format = null) {
    if (IsNull(date) == null) {
        return '';
    }
    return GetLocalTimeFromUTC(date, format);
}


function TruncateStr(str, len) {
    var convertstring = '' + str;
    if (convertstring != null)
        if (convertstring.length <= len) return str;
        else return convertstring.substring(0, len) + "...";
}

function PaginatorTemplate(totalRecords, pageSize) {
    let paginatorTemplate = { default: PaginatorWithPaging }

    if ((totalRecords / pageSize) > 1) {
        paginatorTemplate.default = PaginatorWithPaging;
    }
    else {
        paginatorTemplate.default = PaginatorWithoutPaging;
    }

    return paginatorTemplate
}

function FirstAndLastNameAlpha(firstName, LastName) {
    let result = '';
    if (IsNull(firstName) != null && IsNull(LastName) != null) {
        result = firstName.substr(0, 1).concat(LastName.substr(0, 1)).toUpperCase();
        return result;
    }
    if (IsNull(firstName) != null && IsNull(LastName) == null) {
        result = firstName.substr(0, 2).toUpperCase();
        return result;
    }
    return 'ZU'
}
function fromNow(date, nowDate = Date.now(), rft = new Intl.RelativeTimeFormat(undefined, { numeric: "auto" })) {

    date= GetLocalTimeFromUTC(date);
    const SECOND = 1000;
    const MINUTE = 60 * SECOND;
    const HOUR = 60 * MINUTE;
    const DAY = 24 * HOUR;
    const WEEK = 7 * DAY;
    const MONTH = 30 * DAY;
    const YEAR = 365 * DAY;
    const intervals = [
        { ge: YEAR, divisor: YEAR, unit: 'year' },
        { ge: MONTH, divisor: MONTH, unit: 'month' },
        { ge: WEEK, divisor: WEEK, unit: 'week' },
        { ge: DAY, divisor: DAY, unit: 'day' },
        { ge: HOUR, divisor: HOUR, unit: 'hour' },
        { ge: MINUTE, divisor: MINUTE, unit: 'minute' },
        { ge: 30 * SECOND, divisor: SECOND, unit: 'seconds' },
        { ge: 0, divisor: 1, text: 'just now' },
    ];
    const now = typeof nowDate === 'object' ? nowDate.getTime() : new Date(nowDate).getTime();
    const diff = now - (typeof date === 'object' ? date : new Date(date)).getTime();
    const diffAbs = Math.abs(diff);
    for (const interval of intervals) {
        if (diffAbs >= interval.ge) {
            const x = Math.round(Math.abs(diff) / interval.divisor);
            const isFuture = diff < 0;
            return interval.unit ? rft.format(isFuture ? x : -x, interval.unit) : interval.text;
        }
    }
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default {
    IsNull,
    PaginatorTemplate,
    dateFormat,
    TruncateStr,
    FirstAndLastNameAlpha,
    fromNow, capitalizeFirstLetter
};